<template>
  <div class="modal" id="myModal" style="display: block">
    <div class="modal-dialog center">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ title }}
          </h5>
          <button
            v-if="!hideCancel"
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="$emit('onCancel')"
          ></button>
        </div>
        <div class="modal-body" style="text-align: left">
          <p>{{ text }}</p>
        </div>
        <div class="modal-footer">
          <button
            v-if="!hideCancel"
            type="button"
            class="btn btn-secondary"
            @click="$emit('onCancel')"
          >
            {{ CancelButtonText ? CancelButtonText : "Cancel" }}
          </button>
          <button type="button" class="btn btn-primary" @click="$emit('onOk')">
            {{ okButtonText ? okButtonText : "Yes" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  props: [
    "title",
    "text",
    "okButtonText",
    "CancelButtonText",
    "modal_id",
    "visible",
    "hideCancel",
  ],
  emits: ["onOk", "onCancel"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onOk() {
      this.$emit("onOk");
    },
    onCancel() {
      this.$emit("onCancel");
    },
  },
};
</script>

<style lang="scss" scoped></style>
