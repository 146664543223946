<template>
  <div class="app-content">
    <Header />
    <div class="app-router-view">
      <router-view v-slot="{ Component }">
        <div v-if="error">
          {{ error }}
        </div>
        <Suspense v-else timeout="0">
          <!-- Default -->
          <template #default>
            <component :is="Component" />
          </template>

          <!-- Loading -->
          <template #fallback> 
            <div class="global-padding">loading ...</div>
          </template>
        </Suspense>
        <!-- Error Modal -->
        <confirm-modal
          v-if="errorModal.show"
          :title="errorModal.title"
          :text="errorModal.text"
          :okButtonText="errorModal.okText"
          :hideCancel="true"
          @onOk="() => {errorModal.onOk();}"
        />
      </router-view>
      <spinner />
    </div>
    <div class="inline-block">
      <span v-if="getMarketLabel" class="inline-span-left">
        <b class="inline-text">Market: </b>
        <p class="inline-text">{{ getMarketLabel }}</p>
      </span>
      <span v-if="getProjectLabel" class="inline-span-left">
        <b class="inline-text">Project: </b>
        <p class="inline-text">{{ getProjectLabel }}</p>
      </span>
      <span v-if="getWaveLabel" class="inline-span-left">
        <b class="inline-text">Wave: </b>
        <p class="inline-text">{{ getWaveLabel }}</p>
      </span>
      <span class="inline-span-right">
        <b class="inline-text">Version: </b>
        <p class="inline-text">{{ getVersion }}</p>
      </span>
    </div>
  </div>
</template>

<script>
import ConfirmModal from "@/components/modals/ConfirmModal";
import { defineAsyncComponent, ref, onErrorCaptured } from "vue";

import { mapGetters } from "vuex";
import appService from "./services/appService";

const Spinner = defineAsyncComponent(() =>
  import("@/components/helper/Spinner")
);
const Header = defineAsyncComponent(() => import("@/components/Header"));
const Stepper = defineAsyncComponent(() => import("@/components/Stepper"));

export default {
  name: "App",
  components: {
    ConfirmModal,
    Spinner: Spinner,
    Header: Header,
    Stepper: Stepper,
  },
  setup() {
    const error = ref(null);
    onErrorCaptured((e) => (error.value = e));
    return { error, appService };
  },
  computed: {
    ...mapGetters({
      currentMarket: "users/getUserMarket",
    }),
    getMarketLabel() {
      if (this.$store.getters["users/getUserMarket"]) {
        return (
          this.$store.getters["users/getUserMarketShort"] +
          " (" +
          this.$store.getters["users/getUserMarketName"] +
          ")"
        );
      }
      return undefined;
    },
    getProjectLabel() {
      let activeProject = this.$store.getters["projects/getActiveProject"];
      if (activeProject) {
        return activeProject.name;
      }
      return undefined;
    },
    getWaveLabel() {
      let activeWave = this.$store.getters["waves/getActiveWave"];
      if (activeWave) {
        return activeWave.name;
      }
      return undefined;
    },
    getVersion() {
      return this.$store.getters["getVersion"];
    },
    isOnsite() {
      return this.$store.getters["configurations/isOnSite"];
    },
    errorModal() {
      return this.$store.getters["waves/getErrorModal"];
    },
  },
};
</script>

<style lang="scss" scoped>
.app-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden auto;

  .app-router-view {
    flex: 1;
    // padding: 0px var(--gloabl-padding-right) 0 var(--gloabl-padding-left);
  }
}

.navbar-brand {
  color: $primary !important;
}
.inline-block {
  margin: 5px;
}
.inline-span-left {
  margin-left: 10px;
  margin-right: 10px;
  display: inline;
  float: left;
}
.inline-span-right {
  float: right;
}
.inline-text {
  display: inline;
}
</style>
