import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "./styles/global.scss";

import { t } from "./services/translationService";
import ganttastic from "@infectoone/vue-ganttastic";

import appService from "./services/appService";
import Keycloak from "keycloak-js";

appService.init = false;

let keycloakConfig = appService.getKeycloakConfig();

let initOptions = {
  url: keycloakConfig.url,
  realm: keycloakConfig.realm,
  clientId: keycloakConfig.clientId,
  enableLogging: false,
  checkLoginIframe: false,
  onLoad: "login-required",
};

if (appService.getAccessToken()) {
  initOptions = {
    ...initOptions,
    token: appService.getAccessToken(),
    refreshToken: appService.getRefreshToken(),
    idToken: appService.getIdToken(),
  };
}

let keycloak = new Keycloak(initOptions);

keycloak
  .init(initOptions)
  .success((auth) => {
    if (!auth) {
      appService.removeTokensFromStorage();
      appService.auth = false;
      keycloak.login();
    } else {
      appService.storeTokensInStorage(
        keycloak.token,
        keycloak.idToken,
        keycloak.refreshToken
      );
      appService.configureAxiosSetup();
      appService.configureAxiosSpinner();
      appService.auth = true;

      const app = createApp(App).use(store).use(ganttastic).use(router);
      app.config.globalProperties.$t = t;
      app.mount("#app");
    }
    //Token Refresh
    setInterval(() => {
      keycloak
        .updateToken(70)
        .success((refreshed) => {
          if (refreshed) {
            appService.storeTokensInStorage(
              keycloak.token,
              keycloak.idToken,
              keycloak.refreshToken
            );
            appService.configureAxiosSetup();
            appService.auth = true;
          } else {
            console.warn(
              "Token not refreshed, valid for " +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .error(() => {
          console.error("Failed to refresh token!");
          appService.removeTokensFromStorage();
          appService.auth = false;
          setTimeout(function () {
            window.location.reload();
          }, 10000);
        });
    }, 60000);
  })
  .error(() => {
    console.error("Authentification failed!");
    appService.removeTokensFromStorage();
    appService.auth = false;
    setTimeout(function () {
      window.location.reload();
    }, 10000);
  });

export default keycloak;
